// CustomEvent does not work in IE9 to IE11, for some reason it is defined as an object.

if(typeof(CustomEvent) !== 'function') {
  window.CustomEvent = (name, params) => {
    let opts = {bubbles: false, cancelable: false, detail: undefined}
    Object.entries(params).forEach(entry => {
      const [key, value] = entry;
      opts[key] = value
    })
    const event = document.createEvent('CustomEvent')
    event.initCustomEvent(name, opts.bubbles, opts.cancelable, opts.detail)
    return event
  }

  CustomEvent.prototype = Event.prototype
}
