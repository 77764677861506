export default function formSubmit (elem, success, response) {
  const event = new CustomEvent(
    'formSubmitted',
    {
      detail: { success: success, response: response },
      bubbles: true
    }
  )
  elem.dispatchEvent(event)
}
