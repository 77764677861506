import { closest } from "@/javascript/components/tools/closest"
import formSubmit from "@/javascript/components/events/form_submit"
import { slideTransition } from "@/javascript/components/animations/slide"
import { replaceWith } from "@/javascript/components/tools/replace"
import $ from "jquery"
// Super simple implementation that just replaces the old form with new one for now
// Let's make it more awesome later on

const reloadWrap = (form, slide, fn) => {
  if(slide) {
    slideTransition(form.parentNode, 300, fn)
  } else {
    fn()
  }
}

const reloadFormWith = (form, response, shouldFade, callback) => {
  reloadWrap(form, !shouldFade, () => {
    replaceWith(form, response, { fade: shouldFade })
      .then(callback)
  })
}

const handleJsonResponse = (json, form, success) => {
  formSubmit(form, success, json)
  if(json.redirect) {
    return window.location = json.redirect
  }
}

const resolveJson = (data) => {
  try {
    return JSON.parse(data)
  } catch {
    return data
  }
}

const handleFormResponse = (form, response, success, shouldFade) => {
  if(typeof(response) == 'object') {
    handleJsonResponse(response, form, success)
  } else {
    reloadFormWith(form, response, shouldFade, (newContent) => {
      formSubmit(newContent, success, response)
    })
  }
}

$(document).on('ajax:send', 'form[data-autoupdate]', (e) => {
  e.target.querySelectorAll('input[type=submit]').forEach(button => {
    button.disabled = true
  })
})

// Remote forms with data: autoupdate automatically reload the response into themselves
$(document).on('ajax:error', 'form[data-autoupdate]', function(event, xhr) {
  let responseText = null;

  if (event.detail !== undefined) {
    const [_data, _status, xhr] = event.detail
    responseText = xhr.responseText
  } else {
    responseText = xhr.responseText
  }
  handleFormResponse(this, resolveJson(responseText), false)
})

$(document).on('ajax:success', 'form[data-autoupdate]', function(event, response) {
  if (event.detail !== undefined) {
    const [_data, _status, xhr] = event.detail
    try {
      response = JSON.parse(xhr.response)
    } catch (error) {
      response = xhr.response
    }
  }

  setTimeout(() => {
    event.target.querySelectorAll('input[type=submit]').forEach(button => {
      button.disabled = true
    })
  }, 0)

  handleFormResponse(this, response, true, !closest(event.target, '.modal'))
})
