window.requestAnimationFrame ||= window.webkitRequestAnimationFrame
window.requestAnimationFrame ||= window.mozRequestAnimationFrame

let animationRunning = null

const runCallback = (callback) => {
  if(animationRunning) {
    return
  }
  animationRunning = true
  callback()
  animationRunning = false
}

window.requestAnimationFrame ||= (callback) => {
  setTimeout(() => runCallback(callback), 1000 / 60)
}
